import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HowToHire = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <section id="how-to-hire">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-area wow fadeInLeft">
                  <h2 className="title">
                    CÓMO <span>CONTRATAR</span>
                  </h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="how-to-hire-slider">
                  <Slider {...settings}>
                    <div className="how-to-hire-single-slide">
                      <p className="testi-name">1</p>
                      <p className="testi-company">ELIGE</p>
                      <p className="testi-text">
                        un modelo de invitación y el pack que más se ajuste a
                        tus necesidades.
                      </p>
                    </div>

                    <div className="how-to-hire-single-slide">
                      <p className="testi-name">2</p>
                      <p className="testi-company">CONTÁCTATE</p>
                      <p className="testi-text">
                        con nosotros para asesorarte sobre presupuestos y
                        empezar el trabajo.
                      </p>
                    </div>

                    <div className="how-to-hire-single-slide">
                      <p className="testi-name">3</p>
                      <p className="testi-company">ENVÍA</p>
                      <p className="testi-text">
                        la información necesaria que nos ayudará a construír tu
                        sitio.
                      </p>
                    </div>

                    <div className="how-to-hire-single-slide">
                      <p className="testi-name">4</p>
                      <p className="testi-company">ESPERA</p>
                      <p className="testi-text">
                        de 5 a 7 días para que tu invitación esté terminada.
                      </p>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowToHire;
