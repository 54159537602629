import React from "react";
import WhatsappLogo from "../images/wsp.png";

const Contact = () => {
  return (
    <div>
      <section id="contact">
        <div className="download-overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-area wow fadeInUp">
                  <h2 className="title">
                    INVITAPP<span className="ms-3">RESPONDE </span>
                  </h2>
                  <p>
                    Estamos a tu disposición para atender todas tus consultas y
                    despejar tus dudas. <br />
                    ¡Pedí tu presupuesto sin compromiso!
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="contact-content">
                  <a
                    href="https://api.whatsapp.com/send?phone=543815496469&text=%C2%A1Hola%20Invitapp!%20%F0%9F%98%80%0AMe%20gustar%C3%ADa%20hacer%20una%20consulta"
                    target="_blank"
                  >
                    <button className="contact-button">
                      COMUNICATE CON NOSOTROS
                      <img className="wsp-logo ms-2" src={WhatsappLogo} />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
