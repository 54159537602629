import React from "react";
import pdfFile from "../documents/PacksInvitapp.pdf"

const Packs = () => {
  return (
    <div>
      <section id="packs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-area wow fadeInLeft">
                <h2 className="title">
                  PACKS <span>DISPONIBLES</span>
                </h2>
                <p>
                  Encuentra el pack ideal para tu evento.
                  <br />
                  Conoce más detalles de cada pack haciendo{" "}
                  <a
                    style={{ color: "#ff871c", fontWeight: "bold" }}
                    href={pdfFile}
                    target="_blank"
                  >
                    click acá
                  </a>{" "}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="packs-content">
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="single-plan wow fadeInUp"
                      data-wow-duration="0.5s"
                      data-wow-delay="0.5s"
                    >
                      <div className="single-plan-head">
                        <h5>
                          PACK<span className="ms-3">BÁSICO</span>
                        </h5>
                      </div>
                      <div className="single-plan-content">
                        <ul>
                          <li>Sitio con fotos propias</li>
                          <li>Colores personalizados</li>
                          <li className="delete">Tipografías personalizadas</li>
                          <li>Cuenta regresiva</li>
                          <li>Información de los novios</li>
                          <li>Sección Historia</li>
                          <li>Sección Eventos</li>
                          <li>Sección Regalos</li>
                          <li>Sección Playlist</li>
                          <li>Sección Asistencia</li>
                          <li>Sección Testimonios</li>
                          <li>Galería de fotos</li>
                          <li className="delete">Canción de fondo</li>
                          <li>Mensaje de agradecimiento</li>
                          <li className="delete">Video con fotos</li>
                          <li className="delete">Nueva sección a pedido</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div
                      className="single-plan wow fadeInUp"
                      data-wow-duration="0.75s"
                      data-wow-delay="0.75s"
                    >
                      <div className="single-plan-head">
                        <h5>
                          PACK<span className="ms-3">PREMIUM</span>
                        </h5>
                      </div>
                      <div className="single-plan-content">
                        <ul>
                          <li>Sitio con fotos propias</li>
                          <li>Colores personalizados</li>
                          <li className="delete">Tipografías personalizadas</li>
                          <li>Cuenta regresiva</li>
                          <li>Información de los novios</li>
                          <li>Sección Historia</li>
                          <li>Sección Eventos</li>
                          <li>Sección Regalos</li>
                          <li>Sección Playlist</li>
                          <li>Sección Asistencia</li>
                          <li>Sección Testimonios</li>
                          <li>Galería de fotos</li>
                          <li>Canción de fondo</li>
                          <li>Mensaje de agradecimiento</li>
                          <li>Video con fotos</li>
                          <li className="delete">Nueva sección a pedido</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="single-plan wow fadeInUp"
                      data-wow-duration="0.75s"
                      data-wow-delay="0.75s"
                    >
                      <div className="single-plan-head">
                        <h5>
                          PACK<span className="ms-3">PLATINUM</span>
                        </h5>
                      </div>
                      <div className="single-plan-content">
                        <ul>
                          <li>Sitio con fotos propias</li>
                          <li>Colores personalizados</li>
                          <li>Tipografías personalizadas</li>
                          <li>Cuenta regresiva</li>
                          <li>Información de los novios</li>
                          <li>Sección Historia</li>
                          <li>Sección Eventos</li>
                          <li>Sección Regalos</li>
                          <li>Sección Playlist</li>
                          <li>Sección Asistencia</li>
                          <li>Sección Testimonios</li>
                          <li>Galería de fotos</li>
                          <li>Canción de fondo</li>
                          <li>Mensaje de agradecimiento</li>
                          <li>Video con fotos</li>
                          <li>Nueva sección a pedido</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Packs;
