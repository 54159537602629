import React, { useState, useEffect } from "react";

function Navbar() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Muestra el botón cuando el usuario ha scrolleado hacia abajo
    function handleScroll() {
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <nav
      className={
        isVisible ? "navbar navbar-expand-lg fixed-top" : "navbar hidden"
      }
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul
            className="navbar-nav ms-auto"
          >
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#">
              <i className="fas fa-home me-2 menu-icons"></i>Inicio
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about" >
              <i className="fas fa-envelope me-2 menu-icons"></i> Sobre Invitapp
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#what-includes">
              <i class="fas fa-list-alt me-2 menu-icons"></i>¿Qué incluye?
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#models">
              <i className="fas fa-laptop me-1 menu-icons"></i> Modelos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#how-to-hire">
              <i className="fas fa-handshake me-1 menu-icons"></i> Cómo contratar
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#packs">
              <i className="fas fa-boxes me-2 menu-icons"></i>Packs
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
              <i className="fas fa-phone-square-alt me-2 menu-icons"></i>Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
