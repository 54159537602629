import React from "react";
import HeaderWeb from "../images/header-phone-01.jpg";
import HeaderMobile from "../images/header-mobile.jpg";
import Navbar from "./Navbar";
import InvitappLogo from "../images/invitapp marca-04.png";

const Header = () => {
  return (
    <div>
      <header id="header">
        <div className="header-inner">
          <img src={HeaderWeb} alt="img" className="header-image header-web" />
          <img
            src={HeaderMobile}
            alt="img"
            className="header-image header-mobile"
            rel="noreferrer"
          />
          <div className="overlay">
            <div>
              <Navbar />
            </div>

            <div className="header-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="header-top">
                      <div className="logo-area">
                        <a href="http://invitapp.com.ar">
                          <img
                            src={InvitappLogo}
                            className="invitapp-logo "
                            alt="invitapp-logo"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="header-bottom">
                      <div className="row">
                        <div className="col-md-12 col-sm-6">
                          <div className="header-bottom-left">
                            <h1>Sorprende a tus invitados</h1>
                            <p>con invitaciones únicas y creativas</p>
                            <a href="#about">
                              <button className="default-button">
                                QUIERO SABER MÁS
                              </button>
                            </a>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="header-bottom-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
