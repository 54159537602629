import React from 'react';
import CellPhoneGif from "../images/nexus-6-single.gif"

const WhatIncludes = () => {
  return (
    <div>
  <section id="what-includes">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="title-area">
            <h2 className="title">¿QUE <span>INCLUYE?</span></h2>
            <p>Estas son todas las secciones que una invitación digital pueden tener.<br />¡También puedes agregar una sección personalizada!</p>
          </div>
        </div>
        <div className="col-md-12">
          <div className="what-includes-area">
            <div className="row">
              <div className="col-md-4">
                <div className="what-includes-left">
                  <ul className="what-includes-list what-includes-list-left">
                    <li className="wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay="0.5s">
                      <i className="far fa-calendar-alt"></i>
                      <div className="what-includes-content">
                        <h4>Cuenta regresiva</h4>
                        <p>hasta la fecha del gran día y con un párrafo presentando a los novios.</p>
                      </div>
                    </li>
                    <li className="wow fadeInLeft" data-wow-duration="0.75s" data-wow-delay="0.75s">
                      <i className="fas fa-archive"></i>
                      <div className="what-includes-content">
                        <h4>Sección Historia</h4>
                        <p>resumida en los momentos más importantes en la relación de los novios.</p>
                      </div>
                    </li>
                    <li className="wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                      <i className="fas fa-church"></i>
                      <div className="what-includes-content">
                        <h4>Sección eventos</h4>
                        <p>con links a toda la información de la ceremonia y de la fiesta.</p>
                      </div>
                    </li>
                    <li className="wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                      <i className="fas fa-gifts"></i>
                      <div className="what-includes-content">
                        <h4>Sección regalos</h4>
                        <p>donde puedes compartir los datos bancarios con aquellos que quieran tener un detalle con los novios en su día.</p>
                      </div>
                    </li>
                    <li className="wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                      <i className="fas fa-music"></i>
                      <div className="what-includes-content">
                        <h4>MÚSICA</h4>
                        <p>para personalizar el sitio con tu canción favorita. Además un espacio donde los invitados pueden recomendar canciones para la fiesta.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-img wow fadeInUp">
                  <img src={CellPhoneGif} alt="iPhone mockup" rel="noreferrer"/>
                </div>
              </div>
              <div className="col-md-4">
                <div className="what-includes-right">
                  <ul className="what-includes-list what-includes-list-right">
                    <li className="wow fadeInRight" data-wow-duration="0.5s" data-wow-delay="0.5s">
                      <i className="far fa-check-square"></i>
                      <div className="what-includes-content">
                        <h4>Sección Asistencia</h4>
                        <p>donde los invitados podrán acceder a un formulario para confirmar su asistencia y otros detalles importantes.</p>
                      </div>
                    </li>
                    <li className="wow fadeInRight" data-wow-duration="0.75s" data-wow-delay="0.75s">
                      <i className="fas fa-users"></i>
                      <div className="what-includes-content">
                        <h4>Sección testimonios</h4>
                        <p>un espacio dedicado a compartir buenos deseos y saludos por parte de familiares y amigos.</p>
                      </div>
                    </li>
                    <li className="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
                      <i className="fas fa-photo-video"></i>
                      <div className="what-includes-content">
                        <h4>Galería con fotos</h4>
                        <p>de los novios a lo largo de todo el camino recorrido.</p>
                      </div>
                    </li>
                    <li className="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
                      <i className="fas fa-heart"></i>
                      <div className="what-includes-content">
                        <h4>Sección agradecimientos</h4>
                        <p>con un mensaje especial para tus invitados. Opcionalmente puedes incorporar un video con fotos.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>   
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}

export default WhatIncludes