import './App.css';
import Header from './components/Header';
import About from './components/About';
import WhatIncludes from './components/WhatIncludes';
import Models from './components/Models';
import HowToHire from './components/HowToHire';
import Packs from './components/Packs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import GoToTopButton from './components/GoToTopButton';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <script src="/js/wow.js" type="text/javascript"></script>
        <script src="/js/classie.js" type="text/javascript"></script>
        <script src="/js/jquery.fancybox.pack.js" type="text/javascript"></script>
        <script src="/js/slick.js" type="text/javascript"></script>
        <script src="/js/custom.js" type="text/javascript"></script>
      </Helmet>
      <Header />
      <About />
      <WhatIncludes />
      <Models />
      <HowToHire />
      <Packs />
      <Contact />
      <GoToTopButton />
      <Footer />
    </div>
  );
}

export default App;
