import React, { useState, useEffect } from "react";
import Video from "../images/Model1Example.mp4";
import Video2 from "../images/Model1Example2.mp4";

const About = () => {
  const [videoSrc, setVideoSrc] = useState(Video);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setVideoSrc(Video2);
      } else {
        setVideoSrc(Video);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <section id="about">
        <div className="about-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-area wow fadeInLeft">
                  <h2 className="title">
                    Sobre <span className="ms-2">INVITAPP</span>
                  </h2>
                  <p>
                    <br />
                    ¡El papel quedó atrás!
                    <br />
                    Invitapp es una forma novedosa y sustentable de hacerle saber a tus seres queridos
                    <br />
                    que quieres compartir con ellos ese día tan especial.
                    <br />
                    Te ofrecemos una invitación virtual totalmente personalizada con los datos y fotos de tu evento.
                    <br />
                    Los invitados podrán acceder a toda la información de tu evento con un solo click a través de cualquier dispositivo con acceso a internet!
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="about-video">
                  <iframe
                    src={videoSrc}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
