import React from "react";
import InstaLogo from "../images/Instagram-Icon.png";

const Footer = () => {
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-social">
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-google-plus"></i>
                </a>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
              </div>
              <div className="footer-text">
                <p>
                  Designed by{" "}
                  <a href="https://instagram.com/invitapp.tuc" target="_blank">
                    Invitapp
                  </a>
                  <a href="https://instagram.com/invitapp.tuc" target="_blank">
                    <img className="wsp-logo ms-2" src={InstaLogo} rel="noreferrer" />
                  </a>
                </p>
                <div style={{ fontSize: "13px", marginTop: "5px" }}>
                  &copy; 2023. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
