import React, { useState, useEffect } from "react";

function GoToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-to-top-button">
          <span className="arrow-up">&#8593;</span>
        </button>
      )}
    </>
  );
}

export default GoToTopButton;
