import React from "react";
import Model1 from "../images/model1.jpg";
import Model2 from "../images/model2.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Models = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section id="models">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-area wow fadeInUp">
                <h2 className="title">
                  CONOCÉ NUESTROS<span className="ms-2">MODELOS</span>
                </h2>
                <p>
                  Aquí puedes consultar los modelos disponibles para contratar.
                  Puedes elegir el que más te guste y personalizarlo con fotos y
                  la gama de colores de tu evento*.
                  <br />
                  ¡Muy pronto nuevos modelos disponibles!
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    marginTop: "-55px",
                    color: "grey",
                  }}
                >
                  *El contenido de cada modelo varía según el tipo de pack
                  contratado.
                </p>
              </div>
            </div>
            <div className="col-md-12 diegox">
              <div>
                <Slider {...settings}>
                  <div className="col-md-3">
                    <a
                      href="https://federicoyvalentina1.invitapp.com.ar"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="black-hover">
                        <img
                          src={Model1}
                          alt="app screenshot"
                          className="models-img"
                          rel="noreferrer"
                        />
                        <span className="zoom-icon">
                          <button className="model-button">
                            VER
                            <i class="fas fa-external-link-alt ms-2"></i>{" "}
                          </button>
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-3">
                    <a
                      href="https://federicoyvalentina2.invitapp.com.ar"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="black-hover">
                        <img
                          src={Model2}
                          alt="app screenshot"
                          className="models-img"
                        />
                        <span className="zoom-icon">
                          <button className="model-button">
                            VER
                            <i class="fas fa-external-link-alt ms-2"></i>{" "}
                          </button>
                        </span>
                      </div>
                    </a>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Models;
